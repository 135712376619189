body {
  margin: 0;
  border: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 80px; /* nav-bar-height */
  min-width: 375px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Define global css variables */
:root {
  --nav-bar-height: 80px;
  --light-gray: rgb(248, 249, 250);
}

.navbar {
  min-height: 80px !important;
  min-height: var(--nav-bar-height) !important;
}

.mainContainer {
  min-height: calc(100vh - 80px);
  min-height: calc(100vh - var(--nav-bar-height));
  padding-top: 20px;
}

.plainLink {
  text-decoration: inherit;
  color: inherit;
}

.x-scroller {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

#indexContainer {
  min-height: calc(100vh - var(--nav-bar-height));
  padding-top: 60px;
}

.suggestion_card {
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #7e0131 !important;
  /*background-color: lightgray !important;
  border: 1px solid black !important;*/
  color: white !important;
}

.suggestion_card:hover {
  box-shadow: 0 0 15px rgba(33, 33, 33, 0.3);
}

/* Parallax */

.parallax * {
  box-sizing: border-box;
}

.parallax {
  -webkit-perspective: 100px;
  perspective: 100px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff2eb;
  /*background-color: var(--light-gray);*/
  box-sizing: border-box;
  min-height: 700px;
}

.parallax .parallax_img {
  left: 50%;
  margin-left: -1500px;
  width: 3000px;
  /*height: 1000px;*/
}

.parallax__layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.parallax__layer img {
  display: block;
  position: absolute;
  bottom: 0;
}

.parallax__cover {
  /* background: #2D112B; */
  background: #ffffff;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  min-height: 1600px;
  z-index: 2;
  padding-top: 50px;
}

.parallax__section {
  background-color: gray;
  padding: 20px;
}

.parallax__title {
  /*display: block;
  position: absolute;
  top: 18%;
  margin-left: 10%;
  z-index: 2;
  width: 100%;*/
  z-index: 3;
  top: 26%;
  margin-left: 25%;
  -webkit-transform: translateZ(-250px) scale(3.5);
  transform: translateZ(-250px) scale(3.5);
}

.parallax__layer__0 {
  -webkit-transform: translateZ(-400px) scale(5);
  transform: translateZ(-400px) scale(5);
}

.parallax__layer__1 {
  -webkit-transform: translateZ(-350px) scale(4.5);
  transform: translateZ(-350px) scale(4.5);
}

.parallax__layer__2 {
  -webkit-transform: translateZ(-300px) scale(4);
  transform: translateZ(-300px) scale(4);
}

.parallax__layer__3 {
  -webkit-transform: translateZ(-250px) scale(3.5);
  transform: translateZ(-250px) scale(3.5);
}

.parallax__layer__4 {
  -webkit-transform: translateZ(-200px) scale(3);
  transform: translateZ(-200px) scale(3);
}

.parallax__layer__5 {
  -webkit-transform: translateZ(-150px) scale(2.5);
  transform: translateZ(-150px) scale(2.5);
}

.parallax__layer__6 {
  -webkit-transform: translateZ(-100px) scale(2);
  transform: translateZ(-100px) scale(2);
}

.parallax__layer__7 {
  /*-webkit-transform: translateZ(-50px) scale(1.5);
  transform: translateZ(-50px) scale(1.5);*/
  -webkit-transform: translateZ(-150px) scale(2.5);
  transform: translateZ(-150px) scale(2.5);
}

.parallax__layer__8 {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
  /* filter: brightness(0.25);*/
}

#sortBar {
  height: 60px;
  z-index: 2;
  border-bottom: 1px solid lightgray;
  position: fixed;
  top: 80px;
  background-color: var(--light-gray);
}

@media only screen and (max-width: 1150px) {
  #mapCol {
    display: none;
  }
}

#mapCol {
  height: calc(100vh - 140px);
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  top: 140px;
}

@media only screen and (min-width: 1150px) {
  #floatingMapButtonCol {
    display: none;
  }
  #homeListCol {
    margin-top: 60px;
    display: block;
  }
}

#homeListCol {
  min-height: calc(100vh - 140px);
  display: block;
}

#floatingMapButtonCol {
  text-align: center;
  /*position: fixed;*/
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: calc(100vh - 130px);
  z-index: 3;
  height: 60px;
}

#floatingMapButton {
  margin-right: 10px;
  background-color: rgb(34, 34, 34);
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  color: white;
}

.searchParamButton {
  margin-right: 8px;
  background-color: transparent !important;
  color: gray !important;
}

.searchParamButton:hover,
.searchParamButton:focus {
  box-shadow: inset 0px 0px 0px 2px gray !important;
}

.searchParamButtonSelected {
  margin-right: 8px;
  background-color: gray !important;
  color: white !important;
}

.homeContainer {
  max-height: 200px;
}

.homeEntry {
  cursor: pointer;
}

.homeImageCarousel {
  width: 300px;
  margin-right: 10px;
  height: 200px;
}

@media only screen and (max-width: 763px) {
  .homeImageCarousel {
    width: 150px;
  }
}

.truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

#markerLayer img {
  border: 3px solid white !important;
  border-radius: 50%;
  background-color: gray;
}

.gm-style .gm-style-iw-c {
  padding: 0px !important;
  border-radius: 15px !important;
  height: 265px !important;
  max-height: 265px !important;
  min-height: 265px !important;
}

div.gm-style-iw.gm-style-iw-c div.gm-style-iw-d {
  overflow: unset !important;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

button.custom-map-control-button {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: white;
  border: none;
  border-radius: 2px;
  background-image: url(/static/media/current_location.ddbeeb75.svg);
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

#mainHomeContainer {
  min-height: calc(100vh - 80px);
  margin-top: 20px;
  margin-bottom: 30px;
}

.homeImage {
  opacity: 1;
  transition: 0.3s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background-color: var(--light-gray);
  cursor: pointer;
  object-fit: cover;
}

.homeImage:hover {
  opacity: 0.6;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.selectGuestButtons button:hover {
  background-color: #d1d9e0 !important;
}

#mainBookingContainer {
  min-height: calc(100vh - 80px);
  margin-top: 20px;
  margin-bottom: 20px;
}

.payment-element-container {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid gray;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 316px;
}

.homeCardText {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-tooltip {
  border: 1px solid #f5f5f5;
  background-color: hsla(0, 0%, 100%, 0.8);
  padding: 10px;
}

.custom-tooltip .label {
  margin: 0;
  color: #666;
  font-weight: 700;
}

.custom-tooltip .desc {
  margin: 0;
  color: #999;
}

.custom-tooltip .intro {
  border-top: 1px solid #f5f5f5;
  margin: 0;
}

.radioButton { 
    padding: 0px !important;
    margin-right: 10px !important;
    float: left;
}

.radioButtonLabel {
    width: 100px;
    height: 100px;
    background-color: rgb(233, 236, 239);
    border-radius: 10px;
    padding-top: 25px;
    text-align: center;
}

.radioButton > input { 
    position: absolute;
    opacity: 0;
    width: 0px !important;
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
}

.radioButton > input + label > div {
    cursor: pointer;
}

.radioButton > input:checked + label > div {
    background-color: lightskyblue;
}
.travelEntry {
  cursor: pointer;
}

