#indexContainer {
  min-height: calc(100vh - var(--nav-bar-height));
  padding-top: 60px;
}

.suggestion_card {
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #7e0131 !important;
  /*background-color: lightgray !important;
  border: 1px solid black !important;*/
  color: white !important;
}

.suggestion_card:hover {
  box-shadow: 0 0 15px rgba(33, 33, 33, 0.3);
}

/* Parallax */

.parallax * {
  box-sizing: border-box;
}

.parallax {
  -webkit-perspective: 100px;
  perspective: 100px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff2eb;
  /*background-color: var(--light-gray);*/
  box-sizing: border-box;
  min-height: 700px;
}

.parallax .parallax_img {
  left: 50%;
  margin-left: -1500px;
  width: 3000px;
  /*height: 1000px;*/
}

.parallax__layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.parallax__layer img {
  display: block;
  position: absolute;
  bottom: 0;
}

.parallax__cover {
  /* background: #2D112B; */
  background: #ffffff;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  min-height: 1600px;
  z-index: 2;
  padding-top: 50px;
}

.parallax__section {
  background-color: gray;
  padding: 20px;
}

.parallax__title {
  /*display: block;
  position: absolute;
  top: 18%;
  margin-left: 10%;
  z-index: 2;
  width: 100%;*/
  z-index: 3;
  top: 26%;
  margin-left: 25%;
  -webkit-transform: translateZ(-250px) scale(3.5);
  transform: translateZ(-250px) scale(3.5);
}

.parallax__layer__0 {
  -webkit-transform: translateZ(-400px) scale(5);
  transform: translateZ(-400px) scale(5);
}

.parallax__layer__1 {
  -webkit-transform: translateZ(-350px) scale(4.5);
  transform: translateZ(-350px) scale(4.5);
}

.parallax__layer__2 {
  -webkit-transform: translateZ(-300px) scale(4);
  transform: translateZ(-300px) scale(4);
}

.parallax__layer__3 {
  -webkit-transform: translateZ(-250px) scale(3.5);
  transform: translateZ(-250px) scale(3.5);
}

.parallax__layer__4 {
  -webkit-transform: translateZ(-200px) scale(3);
  transform: translateZ(-200px) scale(3);
}

.parallax__layer__5 {
  -webkit-transform: translateZ(-150px) scale(2.5);
  transform: translateZ(-150px) scale(2.5);
}

.parallax__layer__6 {
  -webkit-transform: translateZ(-100px) scale(2);
  transform: translateZ(-100px) scale(2);
}

.parallax__layer__7 {
  /*-webkit-transform: translateZ(-50px) scale(1.5);
  transform: translateZ(-50px) scale(1.5);*/
  -webkit-transform: translateZ(-150px) scale(2.5);
  transform: translateZ(-150px) scale(2.5);
}

.parallax__layer__8 {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
  /* filter: brightness(0.25);*/
}
