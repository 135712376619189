#markerLayer img {
  border: 3px solid white !important;
  border-radius: 50%;
  background-color: gray;
}

.gm-style .gm-style-iw-c {
  padding: 0px !important;
  border-radius: 15px !important;
  height: 265px !important;
  max-height: 265px !important;
  min-height: 265px !important;
}

div.gm-style-iw.gm-style-iw-c div.gm-style-iw-d {
  overflow: unset !important;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

button.custom-map-control-button {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: white;
  border: none;
  border-radius: 2px;
  background-image: url(../../images/current_location.svg);
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
