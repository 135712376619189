.custom-tooltip {
  border: 1px solid #f5f5f5;
  background-color: hsla(0, 0%, 100%, 0.8);
  padding: 10px;
}

.custom-tooltip .label {
  margin: 0;
  color: #666;
  font-weight: 700;
}

.custom-tooltip .desc {
  margin: 0;
  color: #999;
}

.custom-tooltip .intro {
  border-top: 1px solid #f5f5f5;
  margin: 0;
}
