.homeContainer {
  max-height: 200px;
}

.homeEntry {
  cursor: pointer;
}

.homeImageCarousel {
  width: 300px;
  margin-right: 10px;
  height: 200px;
}

@media only screen and (max-width: 763px) {
  .homeImageCarousel {
    width: 150px;
  }
}

.truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
