.radioButton { 
    padding: 0px !important;
    margin-right: 10px !important;
    float: left;
}

.radioButtonLabel {
    width: 100px;
    height: 100px;
    background-color: rgb(233, 236, 239);
    border-radius: 10px;
    padding-top: 25px;
    text-align: center;
}

.radioButton > input { 
    position: absolute;
    opacity: 0;
    width: 0px !important;
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
}

.radioButton > input + label > div {
    cursor: pointer;
}

.radioButton > input:checked + label > div {
    background-color: lightskyblue;
}