body {
  margin: 0;
  border: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 80px; /* nav-bar-height */
  min-width: 375px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Define global css variables */
:root {
  --nav-bar-height: 80px;
  --light-gray: rgb(248, 249, 250);
}

.navbar {
  min-height: var(--nav-bar-height) !important;
}

.mainContainer {
  min-height: calc(100vh - var(--nav-bar-height));
  padding-top: 20px;
}

.plainLink {
  text-decoration: inherit;
  color: inherit;
}

.x-scroller {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
