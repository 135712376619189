#sortBar {
  height: 60px;
  z-index: 2;
  border-bottom: 1px solid lightgray;
  position: fixed;
  top: 80px;
  background-color: var(--light-gray);
}

@media only screen and (max-width: 1150px) {
  #mapCol {
    display: none;
  }
}

#mapCol {
  height: calc(100vh - 140px);
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  top: 140px;
}

@media only screen and (min-width: 1150px) {
  #floatingMapButtonCol {
    display: none;
  }
  #homeListCol {
    margin-top: 60px;
    display: block;
  }
}

#homeListCol {
  min-height: calc(100vh - 140px);
  display: block;
}

#floatingMapButtonCol {
  text-align: center;
  /*position: fixed;*/
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: calc(100vh - 130px);
  z-index: 3;
  height: 60px;
}

#floatingMapButton {
  margin-right: 10px;
  background-color: rgb(34, 34, 34);
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  color: white;
}

.searchParamButton {
  margin-right: 8px;
  background-color: transparent !important;
  color: gray !important;
}

.searchParamButton:hover,
.searchParamButton:focus {
  -webkit-box-shadow: inset 0px 0px 0px 2px gray !important;
  -moz-box-shadow: inset 0px 0px 0px 2px gray !important;
  box-shadow: inset 0px 0px 0px 2px gray !important;
}

.searchParamButtonSelected {
  margin-right: 8px;
  background-color: gray !important;
  color: white !important;
}
