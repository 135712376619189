#mainHomeContainer {
  min-height: calc(100vh - 80px);
  margin-top: 20px;
  margin-bottom: 30px;
}

.homeImage {
  opacity: 1;
  transition: 0.3s ease;
  backface-visibility: hidden;
  background-color: var(--light-gray);
  cursor: pointer;
  object-fit: cover;
}

.homeImage:hover {
  opacity: 0.6;
}

ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.selectGuestButtons button:hover {
  background-color: #d1d9e0 !important;
}
